.App {
  text-align: center;
  max-width: 80vw;
  margin: auto;
  transition: 1s;
}

.intro-section {
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(10px + 2vmin);
  color: black;
}

.content-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.laura-reasons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 100vh;
  transition: all 2s;
}

.final-photo {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 90vh;
  transition: all 2s;
}

.laura-raaj-photo {
  margin-top: 10vh;
  height: 80vmin;
  z-index: 0;
}

.i-am {
  opacity: 0;
  z-index: 1;
  position: absolute;
  bottom: 70vh;
  margin-top: -70vh;
  transition: opacity 0.4s;
}

.i-am > .title {
  margin: none;
}

.i-am-img {
  position: relative;
  right: 50px;
  top: 20px;
}


.laura-raaj-photo:hover + .i-am {
  opacity: 1;
}

.reasons-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.reasons-display {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 30vw;
}

.reasons-grid button {
  margin: 4px;
  width: 100px;
  font-size: calc(30px + 2vmin);
  width: calc(90px + 2vmin);
  transition: transform 0.4s;
}

.reasons-grid button:hover {
  transform: scale(1.05);
}

.App-link {
  color: #61dafb;
}

.submit-button {
  font-family: 'Pecita';
  background: #EF2E73;
  border-radius: 0 40px 40px 0;
  padding: 5px 20px 5px 20px;
  color: #FFF5EE;
  font-size: 40px;
  border: none;
  margin-top: 50px;
  transition: background 0.4s;
  padding: 10px;
}

.submit-button:hover {
  background: #ff659b;
}

input {
  font-family: 'Pecita';
  font-size: 40px;
  border-color: #EF2E73;
  border-radius: 20px 0 0 20px;
  border-style: none;
  border-width: 4px;
  padding: 10px;
  background: #E9DED6;
}

button {
  font-family: 'Pecita';
  background: #EF2E73;
  border-radius: 40px;
  padding: 10px 30px 10px 30px;
  color: #FFF5EE;
  font-size: 60px;
  border: none;
  margin-top: 50px;
  transition: transform 0.4s;
}

.done-button {
  font-size: 40px;
  margin-top: 0px;
  width: auto;
}

.icon-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.icon-button {
  font-family: 'Pecita';
  background: #EF2E73;
  border-radius: 40px;
  padding: 20px 20px 20px 20px;
  color: #FFF5EE;
  font-size: calc(40px + 2vmin);
  border: none;
  margin-top: 50px;
  width: calc(100px + 2vmin);
}

.icon-button:hover {
  transform: none;
}

button:hover {
  transform: scale(1.05);
}

.name-link {
  transition: color 0.4s;
}

#raaj-link:hover {
  color: #158113;
}

#laura-link:hover {
  color: #FA871D;
}

.d-transparent {
  opacity: 0;
  transition: opacity 0.4s;
}

.d-none {
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.intro-text {
  position: relative;
}

.im-text {
  position: absolute;
  height: calc(70px + 2vmin);
  right: calc(45px + 2vmin);
  bottom: calc(35px + 2vmin);
  transition: opacity 0.4s;
}

h1 {
  margin: 0;
}

.title {
  font-size: 70px;
}